import parseHTML from 'html-react-parser';
import type { Question } from '../../../../data/interface-question';
import QuestionIssues from './QuestionIssues';
import QuestionTags from './QuestionTags';
import { removeEmptyParagraphsbyQuill } from '../../../../utils/helpers';
import QuestionContainerHeader from './QuestionContainerHeader';

interface IQuestionContainer {
	question: Question;
}

const QuestionCard = ({ question }: IQuestionContainer) => {
	return (
		<div className='divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-md mb-4 text-sky-800'>
			<QuestionContainerHeader question={question} />
			<div className='px-4 py-5 sm:p-6'>
				{/* Content goes here */}
				<small>{question.id}</small>
				<p>{parseHTML(removeEmptyParagraphsbyQuill(question.question))}</p>
			</div>
			<div className='px-4 py-4 sm:px-6'>
				{/* Content goes here - We use less vertical padding on card footers at all sizes than on headers or body sections */}
				<QuestionIssues issues={question.issues} />
			</div>
			<QuestionTags tags={question.tags} />
		</div>
	);
};
export default QuestionCard;
