import { useEffect, useState } from 'react';
import useQuestionBankContext from '../../api/QuestionBankContext';
import useUserContext from '../../api/UserContext';
import { useLoadingContext } from '../../context/LoadingContext';
import type { TestRecord, TestType } from '../../data/interface-question';
import {
	calcTestGrade,
	convertFirebaseTSToDate,
	getTestRecordsCorrectAndQuestionTotals
} from '../../utils/helpers';
import Headline from '../../components/ui/Headline';
import Section from '../../components/ui/Section';

interface Values {
	totalPreviousTests: number;
	totalCorrectas: number;
	totalPreguntas: number;
	lastTestScore: string;
	lastTestLength: number;
	lastTestType: string;
}

const Historial = () => {
	const { currentUser } = useUserContext();
	const { testRecords } = useQuestionBankContext();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const [totals, setTotals] = useState<Values>({
		totalPreviousTests: 0,
		totalCorrectas: 0,
		totalPreguntas: 0,
		lastTestScore: '0.00',
		lastTestLength: 0,
		lastTestType: ''
	});

	const calculateValues = async (): Promise<Values | null> => {
		if (testRecords === null) return null;
		if (testRecords?.length === 0) return null;
		const testTotals = await getTestRecordsCorrectAndQuestionTotals(
			testRecords
		);
		return await new Promise((resolve, reject) => {
			const totalPreviousTests = testRecords.length;

			// const totalCorrectas: number = testRecords.reduce(
			// (sum: number, currentValue: TestRecord) => {
			// return sum + currentValue.score;
			// },
			// 0
			// );
			// const totalPreguntas = testRecords.reduce(
			// (sum: number, currentValue: TestRecord) => {
			// return sum + currentValue.testLength;
			// },
			// 0
			// );

			// const lastTest = {
			// ...testRecords.sort((a: TestRecord, b: TestRecord) => {
			// return b.date - a.date;
			// })[0]
			// };

			const sortedRecords = testRecords.sort((a, b) => {
				// return b.date - a.date;
				// todo: remove "Number()"
				return (
					Number(convertFirebaseTSToDate(b.date)) -
					Number(convertFirebaseTSToDate(a.date))
				);
			});
			const lastTest: TestRecord = sortedRecords[0];
			const lastTestLength: number = lastTest.testLength;

			const lastTestType: TestType = lastTest.testType;

			const grade = calcTestGrade(lastTest.correctAnswers, lastTest.testLength);
			resolve({
				totalPreviousTests,
				totalCorrectas: testTotals.totalCorrect,
				totalPreguntas: testTotals.totalQuestions,
				lastTestScore: grade,
				lastTestLength,
				lastTestType
			});
		});
	};

	useEffect(() => {
		if (testRecords !== null) {
			setLoadingMessage('Analizando tu historial...');
			setLoading(true);
			calculateValues()
				.then((values) => {
					if (values !== null) {
						setTotals(values);
					}
					setLoading(false);
				})
				.catch((error) => {
					setLoading(false);
					// TODO: Handle error
					console.error(error);
				});
		}
	}, [testRecords]);
	if (testRecords === null || testRecords.length === 0) return null;

	// TODO: Fix styling once we have records to show
	return (
		<Section
			title='Resumen de Evaluaciones'
			subTitle='Consulta tu rendimiento académico y tu resultado más reciente.'>
			<div>
				{testRecords !== null && testRecords.length > 0 ? (
					<div className='flex flex-wrap items-start'>
						{/* Evaluaciones en total */}
						<div className='w-2/4 md:w-1/4'>
							<div className='card-panel center bg-alternate3 history-card'>
								<strong style={{ fontSize: 'xx-large' }}>
									{totals.totalPreviousTests}
								</strong>
								<p className='fsize-small'>
									{totals.totalPreviousTests === 1
										? 'Evaluación en total'
										: 'Evaluaciones en total'}
								</p>
							</div>
						</div>
						{/* Reducer para promedio de notas */}
						<div className='w-2/4 md:w-1/4'>
							<div className='card-panel center bg-brand txt-brand-white history-card'>
								<strong style={{ fontSize: 'xx-large' }}>
									{`${Math.round(
										((totals.totalCorrectas + Number.EPSILON) /
											totals.totalPreguntas) *
											100
									)}%`}
								</strong>
								<p className='fsize-small'>Nota Promedio</p>
							</div>
						</div>
						{/* Puntuación ultimo examen */}
						<div className='w-2/4 md:w-1/4'>
							<div className='card-panel center bg-alternate2 txt-brand-white history-card'>
								<strong style={{ fontSize: 'xx-large' }}>
									{totals.lastTestScore + '%'}
								</strong>
								<p className='fsize-small'>Nota del último examen</p>
							</div>
						</div>
						<div className='w-2/4 md:w-1/4'>
							<blockquote
								className='border-l-4 border-l-brand-500 pl-4 py-4 md:ml-4'
								style={{ minHeight: '65px' }}>
								{totals.lastTestType === 'personalizado'
									? `Tu último examen fue personalizado con ${totals.lastTestLength} preguntas`
									: `Tu último examen fue un ${totals.lastTestType} de ${totals.lastTestLength} preguntas`}
							</blockquote>
						</div>
					</div>
				) : (
					<p className='card-panel bg-alert'>
						Cuando completes al menos un examen aquí verás tus resultados.
					</p>
				)}
			</div>
		</Section>
	);
};
export default Historial;
