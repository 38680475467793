import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import Headline from './Headline';
interface AlertProps {
	bulletPoints?: string[];
	message?: string;
	children?: React.ReactNode; // best, accepts everything React can render
	classes?: string;
	title: string;
	type: 'brand' | 'info' | 'warning' | 'error' | 'success';
}

// https://tailwindui.com/components/application-ui/feedback/alerts
const Alert = ({
	message,
	bulletPoints,
	children,
	classes = '',
	title,
	type
}: AlertProps) => {
	// Define the color based on the type. It cna be blue if it's info, yellow if it's warning, red if it's error, and green if it's success
	const color =
		type === 'info'
			? 'alt2'
			: type === 'brand'
			? 'brand'
			: type === 'warning'
			? 'yellow'
			: type === 'error'
			? 'red'
			: 'green';
	const bgColor =
		type === 'info'
			? 'bg-alt2-50'
			: type === 'brand'
			? 'bg-brand-100'
			: type === 'warning'
			? 'bg-yellow-50'
			: type === 'error'
			? 'bg-red-50'
			: 'bg-green-50';
	const titleColor =
		type === 'info'
			? 'text-alt2-600'
			: type === 'brand'
			? 'text-brand-800'
			: type === 'warning'
			? 'text-yellow-700'
			: type === 'error'
			? 'text-red-500'
			: 'text-green-500';

	return (
		<div // border-l-4 border-${color}-400
			className={`${bgColor} p-4 ${classes} shadow-lg pb-10 rounded-xl`}
			id='alert'>
			<Headline title={title} type='h5' classes={`${titleColor} mb-2`}>
				<i className='material-icons text-4xl mr-2'>warning</i>
			</Headline>
			{/* <div className='flex'>
				<div className='flex-shrink-0'>
					<ExclamationTriangleIcon
						className={`h-5 w-5 text-${color}-400`}
						aria-hidden='true'
					/>
				</div>
				<div className='ml-3'>
					<p className={`text-sm text-${color}-700`}>
					{message}
					<a href="#" className="font-medium text-yellow-700 underline hover:text-yellow-600">
			  Upgrade your account to add more credits.
			</a>
					</p>
				</div>
			</div> */}
			<div className={`font-medium text-${color}-700 text-sm`}>{children}</div>

			{/* // TODO: Add bullet points
			// TODO: Add buttons / actions */}
			{/* <div className='mt-4'>
				<div className='-mx-2 -my-1.5 flex'>
					<button
						type='button'
						className='rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50'>
						View status
					</button>
					<button
						type='button'
						className='ml-3 rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50'>
						Dismiss
					</button>
				</div>
			</div> */}
		</div>
	);
};

export default Alert;
