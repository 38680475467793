import { Link, useNavigate } from 'react-router-dom';
import Alert from '../../../../components/ui/Alert';
import ProgressBar from '../../../../components/utils/ProgressBar';
import useUserContext from '../../../../api/UserContext';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import type {
	IQuestionBankReducerState,
	Question
} from '../../../../data/interface-question';
import { useLoadingContext } from '../../../../context/LoadingContext';
import { useEffect, useReducer } from 'react';
import QuestionBankReducer from '../../../../api/QuestionBankReducer';
import routes from '../../../../config/routes';
import { set } from 'lodash';

const UnFinishedTest = () => {
	const navigateTo = useNavigate();
	const { currentUser } = useUserContext();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const {
		categories,
		questions,
		testRecords,
		testSelections,
		setTestSelections,
		deleteUnfinishedTest
	} = useQuestionBankContext();

	// #region Reducer Setup
	const initialState: IQuestionBankReducerState = {
		allQuestions: [],
		categories: [],
		especialidades: [],
		label: 'none',
		// questionBank: null, // todo: is this used?
		questionType: 'todas',
		questions: [],
		showCategorySection: true,
		showTopicSelectionBoxes: false,
		testDuration: '',
		testLabel: 'all',
		testLength: 50,
		testMode: 'aleatorio',
		testRecords: testRecords === null ? [] : [...testRecords],
		testType: 'pruebín',
		timerStartTime: 0,
		topics: [],
		topicSubcategories: [],
		useTimer: false,
		useTutorMode: false,
		userCorrectQuestions: [],
		userIncorrectQuestions: []
	};
	const [state, dispatch] = useReducer(QuestionBankReducer, initialState);
	// #endregion

	useEffect(() => {
		if (testSelections !== undefined) {
			dispatch({
				type: 'default',
				payload: testSelections
			});
			navigateTo(routes.APP.PATH_TO.TEST_MODE);
		}
	}, [testSelections]);

	const getPercent = (): number => {
		if (currentUser?.unfinishedTest === undefined) return 0;
		let percent = 0;
		const indexValue = currentUser.unfinishedTest.currentQuestionIndex + 1;
		const value = indexValue / currentUser.unfinishedTest.testLength;
		const round = Math.round(value * 100);
		if (typeof round === 'number') {
			percent = round;
		}
		return percent;
	};

	const continueTest = () => {
		if (currentUser?.unfinishedTest === undefined) return;

		function getTestQuestions(qIDs: string[]): Question[] {
			const questionsToReturn: Question[] = [];
			qIDs.forEach((id) => {
				const fullQuestion = questions?.find((question) => question.id === id);
				if (fullQuestion === undefined) return null;
				questionsToReturn.push(fullQuestion);
			});
			return questionsToReturn;
		}

		const unfinishedTest = currentUser.unfinishedTest;

		const testState: IQuestionBankReducerState = {
			allQuestions: questions === undefined ? [] : [...questions],
			categories: categories === undefined ? [] : [...categories],
			especialidades: unfinishedTest.especialidades,
			label: unfinishedTest.label,
			// questionBank: unfinishedTest.questionBank,
			questionType: unfinishedTest.questionType,
			questions: getTestQuestions(unfinishedTest.questionIDs),
			showCategorySection: unfinishedTest.showCategorySection,
			showTopicSelectionBoxes: unfinishedTest.showTopicSelectionBoxes,
			testDuration: unfinishedTest.testDuration,
			testLabel: unfinishedTest.testLabel,
			testLength: unfinishedTest.testLength,
			testMode: unfinishedTest.testMode,
			testRecords: testRecords === null ? [] : [...testRecords],
			testType: unfinishedTest.testType,
			timerStartTime: unfinishedTest.timerStartTime,
			topics: unfinishedTest.topics,
			topicSubcategories: unfinishedTest.topicSubcategories,
			useTimer: unfinishedTest.useTimer,
			useTutorMode: unfinishedTest.useTutorMode,
			userCorrectQuestions: unfinishedTest.userCorrectQuestions,
			userIncorrectQuestions: unfinishedTest.userIncorrectQuestions,
			isUnfinishedTest: true
		};

		setTestSelections(testState);
	};

	const handleDelete = () => {
		setLoadingMessage('Eliminando examen...');
		setLoading(true);
		deleteUnfinishedTest()
			.then(() => {
				setLoading(false);
				setLoadingMessage('');
			})
			.catch((error) => {
				setLoading(false);
				setLoadingMessage('');
				console.error(error);
			});
	};

	if (currentUser?.unfinishedTest === undefined) {
		return null;
	}

	return (
		<Alert
			title='Tienes un examen por terminar...'
			type='brand'
			classes='mx-auto max-w-7xl py-10 px-4 sm:px-6 lg:px-8 rounded-xl shadow my-8'>
			<p>
				Tienes un pendiente de terminar. Si quieres continuar ahora, dale al
				botón para &rsquo;Completar Examen&rsquo;. Si no quieres terminarlo y
				prefieres empezar otro, puedes eliminarlo dándole a &rsquo;Eliminar
				Examen&rsquo;.
			</p>
			{
				<ProgressBar
					backgroundColor='bg-brand-25'
					width={100}
					percent={getPercent()}
				/>
			}
			<div className='flex justify-end mb-4'>
				{currentUser.unfinishedTest.currentQuestionIndex + 1}/
				{currentUser.unfinishedTest.testLength}
			</div>
			<button className='bg-red-400 mr-4' onClick={handleDelete}>
				Eliminar
			</button>
			<button className='bg-brand-500' onClick={continueTest}>
				Completar
			</button>
		</Alert>
	);
};
export default UnFinishedTest;
