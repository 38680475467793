import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Pagination from '../../../components/ui/Pagination';
import type { Question } from '../../../data/interface-question';
import useQuestionBankContext from '../../../api/QuestionBankContext';
import QuestionCard from './question-item/QuestionContainer';

const PageSize = 10;
const QuestionList = () => {
	const { questions } = useQuestionBankContext();
	// DATA STATE
	const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([]);
	// PAGINATION STATE
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalQuestions, setTotalQuestions] = useState<Question[]>([]);
	const currentTableData = useMemo(() => {
		const questionList =
			filteredQuestions.length > 0 ? filteredQuestions : questions ?? [];

		// SORTING
		const data = questionList?.sort((a: Question, b: Question) => {
			let sortMethod = 0;
			b.createdOn > a.createdOn ? (sortMethod = 1) : (sortMethod = -1);
			return sortMethod;
		});

		setTotalQuestions(data);
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		return data.slice(firstPageIndex, lastPageIndex);
	}, [currentPage, filteredQuestions, questions]);

	return (
		<div>
			{/* //TODO: Add search & filter  */}
			Search & Filter <br />
			{/* PAGINATION */}
			<div className='flex justify-end'>
				<Pagination
					className='pagination-bar'
					currentPage={currentPage}
					siblingCount={1}
					totalCount={totalQuestions.length}
					pageSize={PageSize}
					onPageChange={(page) => setCurrentPage(page)}
				/>
			</div>
			{/* DISPLAY DATA */}
			{currentTableData.map((question) => {
				return (
					<Link to={'/question-details/' + question.id} key={question.id}>
						<QuestionCard question={question} />
					</Link>
				);
			})}
		</div>
	);
};
export default QuestionList;
