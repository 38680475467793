import type { Question } from '../../../../data/interface-question';

interface IQuestionContainer {
	question: Question;
}

const QuestionContainerHeader = ({ question }: IQuestionContainer) => {
	return (
		<div className='px-4 py-5 sm:px-6'>
			{/* Content goes here */}
			{/* We use less vertical padding on card headers on desktop than on body sections */}

			{Object.prototype.hasOwnProperty.call(question, 'audioFileName') && (
				<span>
					<i className='material-icons small'>audio_file</i>
				</span>
			)}
			{Object.prototype.hasOwnProperty.call(question, 'imageFileName') && (
				<span>
					<i className='material-icons small'>image</i>
				</span>
			)}
			{Object.prototype.hasOwnProperty.call(question, 'categoryID') ? (
				<span>
					{Object.prototype.hasOwnProperty.call(question, 'issues') ? (
						<span>
							<i
								className='material-icons'
								style={{ fontSize: '2.5rem', color: 'var(--red)' }}>
								priority_high
							</i>
						</span>
					) : (
						''
					)}
					<strong>Catogoría:</strong> {question.categoryID}
				</span>
			) : (
				<span className='red-text'>
					<i className='material-icons' style={{ fontSize: '2.5rem' }}>
						warning
					</i>{' '}
					No Category Selected
				</span>
			)}

			{Object.prototype.hasOwnProperty.call(question, 'source') &&
			typeof question.source === 'string' ? (
				<span
					className={`badge badge-spacing ${
						question.source === 'prepmed' ? 'bg-brand-2' : 'bg-alternate1-1'
					} white-text`}>
					{question.source.toUpperCase()}
				</span>
			) : (
				<span className='badge badge-spacing red white-text'>
					No label selected
				</span>
			)}
		</div>
	);
};
export default QuestionContainerHeader;
