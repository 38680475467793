import { Link, Outlet, useLocation } from 'react-router-dom';
import routes from '../../config/routes';
import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
	ArrowLeftEndOnRectangleIcon,
	Bars3Icon,
	UserIcon,
	XMarkIcon
} from '@heroicons/react/24/outline';
import Logo from '../../components/Logo';
import { useAuthContext } from '../../api/AuthContext';
import useUserContext from '../../api/UserContext';

export interface SideBarLinkProps {
	name: string;
	href: string;
	icon: any;
	current: boolean;
}
export interface OtherLinkProps {
	id: number;
	name: string;
	href: string;
	initial: string;
	current: boolean;
}

interface SideBarNavigationProps {
	currentPageName: string;
	navigation: SideBarLinkProps[];
	otherLinks: OtherLinkProps[];
	handleNavigation: (name: string) => void;
}

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

const SideBarLayout = ({
	currentPageName,
	handleNavigation,
	navigation,
	otherLinks
}: SideBarNavigationProps) => {
	const location = useLocation();
	const { currentUser } = useUserContext();
	const { signOutCurrentUser } = useAuthContext();
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);

	useEffect(() => {
		if (currentUser !== null) {
			setIsAdmin(currentUser?.isAdmin ?? false);
		}
	}, [currentUser]);

	const signOut = () => {
		signOutCurrentUser()
			.then(() => {
				console.log('User signed out successfully');
			})
			.catch((error) => {
				console.error('Error signing out: ', error);
			});
	};

	return (
		<>
			<Transition.Root show={sidebarOpen} as={Fragment}>
				<Dialog
					as='div'
					className='relative z-50 lg:hidden'
					onClose={setSidebarOpen}>
					<Transition.Child
						as={Fragment}
						enter='transition-opacity ease-linear duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='transition-opacity ease-linear duration-300'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'>
						<div className='fixed inset-0 bg-gray-900/80' />
					</Transition.Child>

					<div className='fixed inset-0 flex'>
						<Transition.Child
							as={Fragment}
							enter='transition ease-in-out duration-300 transform'
							enterFrom='-translate-x-full'
							enterTo='translate-x-0'
							leave='transition ease-in-out duration-300 transform'
							leaveFrom='translate-x-0'
							leaveTo='-translate-x-full'>
							<Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
								<Transition.Child
									as={Fragment}
									enter='ease-in-out duration-300'
									enterFrom='opacity-0'
									enterTo='opacity-100'
									leave='ease-in-out duration-300'
									leaveFrom='opacity-100'
									leaveTo='opacity-0'>
									<div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
										<button
											type='button'
											className='-m-2.5 p-2.5'
											onClick={() => setSidebarOpen(false)}>
											<span className='sr-only'>Close sidebar</span>
											<XMarkIcon
												className='h-6 w-6 text-white'
												aria-hidden='true'
											/>
										</button>
									</div>
								</Transition.Child>
								{/* Sidebar component, swap this element with another sidebar if you like */}
								<div className='flex grow flex-col gap-y-5 overflow-y-auto bg-brand-700 px-6 pb-2'>
									<div className='flex h-16 shrink-0 items-center'>
										<img
											className='h-8 w-auto'
											src='https://tailwindui.com/img/logos/mark.svg?color=white'
											alt='Your Company'
										/>
									</div>
									{/* SIDE BAR NAV */}
									<nav className='flex flex-1 flex-col'>
										<ul role='list' className='flex flex-1 flex-col gap-y-7'>
											{/* MAIN NAV */}
											<li>
												<ul role='list' className='-mx-2 space-y-1'>
													{navigation.map((link) => (
														<li key={link.name}>
															<Link
																to={link.href}
																onClick={() => handleNavigation(link.name)}
																className={classNames(
																	link.current
																		? 'bg-sky-700 text-brand-25'
																		: 'text-brand-50 ',
																	'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:bg-sky-700'
																)}>
																<link.icon
																	className={classNames(
																		link.current
																			? 'text-brand-100'
																			: 'text-brand-200 group-',
																		'h-6 w-6 shrink-0'
																	)}
																	aria-hidden='true'
																/>
																{link.name}
															</Link>
														</li>
													))}
												</ul>
											</li>
											{/* OTROS */}
											<li>
												<div className='text-xs font-semibold leading-6 text-brand-200'>
													Otros
												</div>
												<ul role='list' className='-mx-2 mt-2 space-y-1'>
													{otherLinks.map((link) => (
														<li key={link.name}>
															<Link
																to={link.href}
																onClick={() => handleNavigation(link.name)}
																className={classNames(
																	link.current
																		? 'bg-sky-700 text-brand-25'
																		: 'text-brand-50 ',
																	'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:bg-sky-700'
																)}>
																<span className='flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-brand-400 bg-brand-500 text-[0.625rem] font-medium text-white'>
																	{link.initial}
																</span>
																<span className='truncate'>{link.name}</span>
															</Link>
														</li>
													))}
												</ul>
											</li>
											{isAdmin && (
												<li>
													{/* // TODO: Hard coded admin path */}
													<div className='text-xs font-semibold leading-6 text-brand-200'>
														Admin Links
													</div>
													<ul role='list' className='-mx-2 mt-2 space-y-1'>
														<li>
															<Link
																to={routes.ADMIN_LINKS.QUESTIONS}
																className='text-brand-100 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:bg-sky-700'>
																<span className='flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-brand-400 bg-brand-500 text-[0.625rem] font-medium text-white'>
																	Q
																</span>
																<span className='truncate'>User Report</span>
															</Link>
														</li>
														<li>
															<Link
																to={routes.ADMIN_LINKS.USER_REPORTS}
																className='text-brand-100 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:bg-sky-700'>
																<span className='flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-brand-400 bg-brand-500 text-[0.625rem] font-medium text-white'>
																	U
																</span>
																<span className='truncate'>User Report</span>
															</Link>
														</li>
													</ul>
												</li>
											)}
											<li className='mt-auto'>
												<Link
													to={routes.HOME}
													onClick={() => signOut()}
													className={classNames(
														'text-brand-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:bg-sky-700'
													)}>
													<ArrowLeftEndOnRectangleIcon
														className='h-6 w-6 shrink-0 text-brand-50 group- hover:bg-sky-700'
														aria-hidden='true'
													/>
													Cerrar Sesión
												</Link>
											</li>
										</ul>
									</nav>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
			{/* // TODO: Add a transition for the sidebar
			// TODO: Separate the mobile nav and statis sidebar into components */}
			{/* Static sidebar for desktop */}
			{/* lg:w-72 */}
			<div className='hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col xl:w-72'>
				{/* Sidebar component, swap this element with another sidebar if you like */}
				<div className='flex grow flex-col gap-y-5 overflow-y-auto bg-sky-800 px-6'>
					<div className='flex h-16 shrink-0 items-center mt-8'>
						<Logo />
					</div>
					<nav className='flex flex-1 flex-col'>
						<ul role='list' className='flex flex-1 flex-col gap-y-7'>
							{/* MAIN NAV */}
							<li>
								<ul role='list' className='-mx-2 space-y-1'>
									{navigation.map((link) => (
										<li key={link.name}>
											<Link
												to={link.href}
												onClick={() => handleNavigation(link.name)}
												className={classNames(
													link.current
														? 'bg-sky-700 text-brand-25'
														: 'text-brand-50 ',
													'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:bg-sky-700'
												)}>
												<link.icon
													className={classNames(
														link.current
															? 'text-brand-100'
															: 'text-brand-200 group-',
														'h-6 w-6 shrink-0'
													)}
													aria-hidden='true'
												/>
												{link.name}
											</Link>
										</li>
									))}
								</ul>
							</li>

							{/* OTROS */}
							<li>
								<div className='text-xs font-semibold leading-6 text-brand-200'>
									Otros enlaces
								</div>
								<ul role='list' className='-mx-2 mt-2 space-y-1'>
									{otherLinks.map((link) => (
										<li key={link.name}>
											<Link
												to={link.href}
												onClick={() => handleNavigation(link.name)}
												className={classNames(
													link.current
														? 'bg-sky-700 text-brand-25'
														: 'text-brand-50   ',
													'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:bg-sky-700'
												)}>
												<span className='flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-brand-400 bg-brand-500 text-[0.625rem] font-medium text-brand-25'>
													{link.initial}
												</span>
												<span className='truncate'>{link.name}</span>
											</Link>
										</li>
									))}
								</ul>
							</li>
							{isAdmin && (
								<li>
									{/* // TODO: Hard coded admin path */}
									<div className='text-xs font-semibold leading-6 text-brand-200'>
										ADMIN LINKS
									</div>
									<ul role='list' className='-mx-2 mt-2 space-y-1'>
										<li>
											<Link
												to={routes.ADMIN_LINKS.QUESTIONS}
												className='text-brand-200 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:bg-sky-700'>
												<span className='flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-brand-400 bg-brand-500 text-[0.625rem] font-medium text-white'>
													Q
												</span>
												<span className='truncate'>Questions Mgmt</span>
											</Link>
										</li>
										<li>
											<Link
												to={routes.ADMIN_LINKS.USER_REPORTS}
												className='text-brand-200 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold hover:bg-sky-700'>
												<span className='flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-brand-400 bg-brand-500 text-[0.625rem] font-medium text-white'>
													U
												</span>
												<span className='truncate'>User Report</span>
											</Link>
										</li>
									</ul>
								</li>
							)}
							<li className='-mx-6 mt-auto'>
								<Link
									to={routes.APP.USER_PROFILE}
									className='flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-brand-200 hover:bg-sky-700'>
									{/* <img
										className='h-8 w-8 rounded-full bg-brand-750'
										src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
										alt=''
									/> */}
									<UserIcon className='h-6 w-6' aria-hidden='true' />
									<span className='sr-only text-brand-25'>Tu perfil</span>
									<span aria-hidden='true' className='text-brand-25'>
										{currentUser?.person?.firstName}{' '}
										{currentUser?.person?.lastName}
									</span>
								</Link>
								<Link
									to={routes.HOME}
									onClick={() => signOut()}
									className='flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-brand-200 hover:bg-sky-700'>
									<ArrowLeftEndOnRectangleIcon
										className='h-6 w-6'
										aria-hidden='true'
									/>
									<span className='text-brand-25'>Cerrar sesión</span>
								</Link>
							</li>
						</ul>
					</nav>
				</div>
			</div>
			<div className='sticky top-0 z-40 flex items-center gap-x-6 bg-brand-700 px-4 py-4 shadow-sm sm:px-6 lg:hidden'>
				<button
					type='button'
					className='-m-2.5 p-2.5 text-brand-200 lg:hidden'
					onClick={() => setSidebarOpen(true)}>
					<span className='sr-only'>Open sidebar</span>
					<Bars3Icon className='h-6 w-6' aria-hidden='true' />
				</button>
				<div className='flex-1 text-sm font-semibold leading-6 text-white'>
					{currentPageName}
				</div>
				<Link to={routes.APP.USER_PROFILE}>
					<span className='sr-only'>Your profile</span>
					{/* <img
						className='h-8 w-8 rounded-full bg-brand-750'
						src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
						alt=''
					/> */}
					<UserIcon className='h-6 w-6 text-white' aria-hidden='true' />
				</Link>
			</div>
			<Outlet />
		</>
	);
};
export default SideBarLayout;
