import parseHTML from 'html-react-parser';
import { removeEmptyParagraphsbyQuill } from '../../../../utils/helpers';
import AudioExplanation from '../AudioExplanation';
import ImageDisplay from '../ImageDisplay';
import Fuente from './Fuente';
import { useRef, useState } from 'react';
interface ExplanationProps {
	id: string;
	explanation: string;
	fuentes?: string;
	audioFileName: string;
	imagePathName: string;
	showImageInQuestion: boolean;
}

const Explanation = ({
	explanation,
	fuentes,
	audioFileName,
	imagePathName,
	showImageInQuestion
}: ExplanationProps) => {
	const [isOpen, setOpen] = useState(false);
	const handleClick = () => {
		setOpen(!isOpen);
	};

	return (
		<div>
			<div className='flex justify-end'>
				<button
					className='cursor-pointer text-brand-500 hover:text-brand-600'
					onClick={handleClick}>
					{isOpen ? 'Cerrar' : 'Ver'} Explicación
				</button>
			</div>
			{isOpen && (
				<div className='bg-brand-100 rounded-lg drop-shadow-md p-4'>
					<h6>Explicación:</h6>
					<AudioExplanation audioFileName={audioFileName} />
					{!showImageInQuestion && (
						<ImageDisplay imagePathName={imagePathName} />
					)}
					<div className='explanation'>
						{parseHTML(removeEmptyParagraphsbyQuill(explanation))}
					</div>
					<Fuente fuentes={fuentes} />
				</div>
			)}
		</div>
	);
	// return (
	// <details>
	// <summary
	// className='text-right cursor-pointer hover:text-brand-600'
	// onClick={handleClick}>
	// {isOpen ? 'Cerrar' : 'Ver'} Explicación
	// </summary>
	// <div className='bg-brand-100 rounded-lg drop-shadow-md p-4'>
	// <h6>Explicación:</h6>
	// <AudioExplanation audioFileName={audioFileName} />
	// {!showImageInQuestion && <ImageDisplay imagePathName={imagePathName} />}
	// <div className='explanation'>
	// {parseHTML(removeEmptyParagraphsbyQuill(explanation))}
	// </div>
	// <Fuente fuentes={fuentes} />
	// </div>
	// </details>
	// );
};
export default Explanation;
