import MainSection from '../../../layout/app/dashboard/MainSection';
import QuestionList from './QuestionList';

const QuestionsManagerDashboard = () => {
	return (
		<MainSection title='Questions Manager' bgColor='bg-brand-25'>
			<div className='my-8 w-full'>
				<div className='px-4 sm:px-6 lg:px-8'>
					<div>Stats</div>
					<hr />
					<QuestionList />
				</div>
			</div>
		</MainSection>
	);
};
export default QuestionsManagerDashboard;
