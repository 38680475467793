interface IQuestionTags {
	tags: string[];
}

const QuestionTags = ({ tags }: IQuestionTags) => {
	if (tags.length > 0) return null;
	return (
		<div className='px-4 py-4 sm:px-6'>
			<ul>
				{tags.map((tag: string) => {
					return (
						<li
							className='chip text-brand-300'
							key={tag}
							id='tags'
							style={{ margin: '0' }}>
							{tag}
						</li>
					);
				})}
			</ul>
		</div>
	);
};
export default QuestionTags;
